import React from "react";
import FooterBB from "components/Vetrina/Footer";
import { Grid2 as Grid, Typography } from "@mui/material";
import PngAzienda from "../../immagini/vetrina_aziende.png";
import AppBarJob from "components/Vetrina/AppBarJob";
import JobErButton from "components/JobErButton";
import { useNavigate } from "react-router-dom";

function Aziende(props) {
  const navigate = useNavigate();

  return (
    <>
      <AppBarJob bgcolor="primary.main" color="white.main" activePage={"Aziende"} onUserLogin={(user) => props.onUserLogin(user)}></AppBarJob>
      <Grid container spacing={3} backgroundColor="primary.main" sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 } }} pb={10}>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "none", md: "block" },
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="azienda"
            src={PngAzienda}
            style={{
              height: "65vh",
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} sx={{ display: { xs: "block", md: "none" }, justifyContent: "center" }}>
          <img
            alt="azienda"
            src={PngAzienda}
            style={{
              height: "43vh",
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
          size={{ xs: 12, md: 6 }}
        >
          <Grid container spacing={4}>
            <Grid size={12}>
              <Typography variant="h1" color="white.main" sx={{ textAlign: "left!important" }}>
                Stai cercando i migliori talenti per la tua azienda?
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="h3" color="white.main" sx={{ textAlign: "left" }} mt={2}>
                {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? "MyLapam Job" : "Job Confartigianato"} mette a disposizione per le aziende associate e non
                associate questo servizio di ricerca e selezione del personale.
              </Typography>
            </Grid>
            {process.env.REACT_APP_AMBIENTE !== "modena-reggio" && (
              <Grid size="auto">
                <JobErButton color="white" variant="contained" size="large" onClick={() => navigate("/Vetrina/Registrati")}>
                  Registrati
                </JobErButton>
              </Grid>
            )}
            <Grid size="auto">
              <JobErButton
                size="large"
                color="white"
                variant={process.env.REACT_APP_AMBIENTE !== "modena-reggio" ? "outlined" : "contained"}
                onClick={() => navigate("/Vetrina/Contattaci")}
              >
                Contattaci
              </JobErButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Typography color="white" variant="h3" fontWeight={"bold"}>
            Le fasi del nostro lavoro:
          </Typography>
          <Typography color="white" variant="h3">
            <ul>
              <li>analisi dell'esigenza del cliente;</li>
              <li>elaborazione della job description condivisa con il cliente;</li>
              <li>attività accurata di ricerca e selezione;</li>
              <li>attivazione dei canali di recruiting;</li>
              <li>individuazione delle candidature idonee;</li>
              <li>colloqui individuali con i nostri esperti;</li>
              <li>presentazione delle candidature al cliente finale;</li>
              <li>reporting sui risultati ottenuti;</li>
              <li>supporto al cliente nella fase di colloquio in presenza del candidat*;</li>
              <li>periodo di valutazione del candidat* e del suo percorso in azienda;</li>
            </ul>
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography color="white" variant="h3">
            Potrai scegliere tra due diverse tipologie di ricerca:
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography color="white" variant="h3">
            <b>
              <u>Ricerca passiva</u>:{" "}
            </b>
            i candidati che visualizzeranno l’annuncio si candideranno autonomamente e potranno essere sbloccati i dati per il contatto solo dall’azienda.
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography color="white" variant="h3">
            <b>
              <u>Ricerca attiva</u>:{" "}
            </b>
            gestiremo noi l’annuncio. Faremo in modo di presentarti i migliori candidati. Il costo sarà per assunzione.
            <ul>
              <li>
                Gestione fatta totalmente dai nostri consulenti interni di{" "}
                {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? "MyLapam Job" : "Job Confartigianato"};
              </li>
              <li>costo solo in caso di assunzione;</li>
              <li>ricerca dei migliori candidati da tutte le piattaforme disponibili;</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
      <FooterBB />
    </>
  );
}
export default Aziende;
